@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --cor-sub-lista-navbar: #000080; /*lista de hover*/
  --cor-sub-lista-itens-hover: #fff; /* hover nos itens da lista  */
  --cor-sub-lista-navbar-txt-hover: #fff;
  --cor-sub-lista-navbar-txt: #fff;
  --cor-acessibilidade: #000080; /*background do acessibilidade*/
  --cor-icones-acessibilidade-CM: #222;
  --cor-footer: #000080;
  --cor-txt-navbar: #000080; /* cor texto da lista */
  --cor-icones-navbar: #000080;
  --cor-arrow-navbar: #fff; /* Cor das setas da lista  */

  --cor-txt-Title: #000080;
  --cor-txt-subTitle: #000080;
  --cor-icone-subTitle: #000080;

  --cor-botao-pagina-principal: #000080; /*corona-noticias-esic*/
  --cor-txt-botao: #ffffff;

  --cor-nome-portal: #000080;

  --Bem-vindo-color: #ececeb;
  --Bem-vindo-sombra: #222;

  --text-rota-pagina: #26100b;
  --text-layout: #fff;
  --text-layout2: #fff;
  --borda-layout: #fff;
  --borda-layout2: #fff;

  --cor-texto-footer: #fff;
  --cor-texto-footer-desc: #ebebeb;
  --cor-diferenca-portal: #000085;

  --cor-linha-divisão-txt: #000080;

  --fundo-login-01: #0b48d8;
  --fundo-login-02: #0f5ff4;

  --cor-icons-card-sacop: #000080;

  --cor-icon-download-DOWNLOAD: #000080;
  --cor-icon-download-SEARCH: #000080;

  --cor-icon-download-CSV: rgb(16 81 133);
  --cor-icon-download-XLS: #56964c;
  --cor-icon-download-PDF: #ff1b0e;
  --cor-icon-download-XML: #fc8330;
  --cor-icon-download-JSON: #673ee5;

  --cor-icone-buscar-atos-administrativos: #222;

  --cor-fundo-navbar-CM: #000080;
  --cor-fundo-accessibility-navbar-CM: #ffff; /* fundo dos icones  */
  --cor-fundo-input-buscar-navbar-CM: #ffff;
  --cor-placeholder-navbarCM: #000080;
  --cor-icone-buscar-navbar-CM: #000080;
  --cor-line-name-portal-CM: #000080;
  --cor-icone-navbar-phone: #000080;

  --cor-Title: #000080;

  /* Diário */
  --diary-card: #000080;
  --diary-card-txt: #ffffff;
  --diary-card-icone: #ffffff;
  --diary-card-link-hover: #2d2d2d;

  /* Diário box de detalhes */

  --diary-box-detail-fundo: #ffffff;
  --diary-box-detail-icone: #000080;

  /* Diário tabela */

  --diary-table-txt: #000080; /* Linhas tbm nessa cor  */
  --diary-table-icone: #000080;

  /* --Ouvidoria */
  --cor-border-ouvidoria: #000080;
  /* Corrigir estilo */
  --cor-subTitle-ouvidoria: #222;

  /* Sala Empreeendedor */
  --cor-fundo-sala-empreendedor: #000080;
  --cor-texto-card-sala-empreendedor: #ffff;
  --cor-Portal-RGB: rgb(0, 104, 185);
  --cor-fundo-sala-empreendedor-RGB: rgb(0, 104, 185);
  --cor-fundo-sala-empreendedor-RGB: rgb(0, 104, 185);
  --RGB1-sombra-card-sala-empreendedor: 0;
  --RGB2-sombra-card-sala-empreendedor: 104;
  --RGB3-sombra-card-sala-empreendedor: 185;

  --cor-fundo-btn-sala-empreendedor: #000080;
  --cor-btn-letra-sala-empreendedor: #ffffff;
  --cor-btn-hover-letra-sala-empreendedor: #fff;

  /* ESIC */
  --esic-resultado-cor-txt: #000080;
  --esic-resultado-cor-icone: #000080;
}
/* Remover marca reactflow - organograma */
.react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
}
body {
  overflow-x: hidden;
}
/* CSS esconder icones de Libra e Acessibilidade */

.pop-up {
  display: none !important;
  z-index: -5;
}
.access-button {
  display: none !important;
}
.uai {
  display: none !important;
}
.section-title.rotes_pages {
  color: #222;
}
.section-title {
  margin: 0 auto;
  max-width: 1350px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.cardTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.section-title h5 {
  color: var(--cor-Title);
  margin: 0;
}
.title-home {
  margin-left: 5%;
  margin-right: 11px;
  width: 1.4%;
}
.icon-title {
  fill: var(--cor-icone-subTitle);
}
.section-title-portarias {
  color: var(--cor-txt-Title);
}

.section-title.rotes_pages {
  /* PADDING: 20PX; */
  padding-left: 33px;
}
a {
  text-decoration: none;
}
.border {
  border: 1px solid var(--cor-linha-divisão-txt) !important;
}

.icon-title {
  width: 25px;
  margin-right: 0.5rem;
}
.pagination {
  justify-content: center;
}
.main-footer {
  /* rodapé */
  /* cor no fundo */

  background-color: var(--cor-footer);

  /* Imagem de fundo */
  /* background-image: url("../../public/imagens/IMG_1435.JPG"); */
  /* background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 color: rgb(0, 0, 0);   */
}
.background-global {
  /* Dependendo da cor do fundo escolhido a cor do texto muda tbm  */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(0, 0, 0);
  padding: 12%;

  /* background-image: url("../../public/imagens/fundo-site.jpeg");
  background-position-x: center;
  background-position-y: -436px; */
  color: #fff;
}
/* Colorido/*/
.background-global2 {
  padding: 12%;
  color: rgb(255, 255, 255);
  background: linear-gradient(45deg, #019b3b, #000080, #076e3f, #fedf01);
  background-size: 300% 300%;
  animation: colors 5s ease infinite;
}
.spaceDivCM {
  padding: 4rem;
  color: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    var(--cor-fundo-navbar-CM) 10%,
    #ffffff 33%
  );
  background-size: 300% 300%;
}
.GroupBottomCM {
  position: relative;
}
.icon-download-CSV {
  fill: var(--cor-icon-download-CSV);
}
.icon-download-XLS {
  fill: var(--cor-icon-download-XLS);
}
.icon-download-PDF {
  fill: var(--cor-icon-download-PDF);
}
.icon-download-XML {
  fill: var(--cor-icon-download-XML);
}
.icon-download-JSON {
  fill: var(--cor-icon-download-JSON);
}
.icon-download-DOWNLOAD {
  fill: var(--cor-icon-download-DOWNLOAD);
}
.icon-download-SEARCH {
  fill: var(--cor-icon-download-SEARCH);
}

/* carrossel galeria competências */
.cardCarouselOffice.carousel.slide {
  margin: 30px 0;
}
.carouselOffice.carousel-caption {
  background-color: var(--cor-footer) !important;
  color: #ffffff !important;
  position: static;
  bottom: -24px;
  margin: 15px 10%;
  border-radius: 15px;
  margin: 13px 20%;
  height: 100px;
}
.w-200 {
  width: auto !important;
  margin: 0 auto !important;
  height: 199px !important;
}

div.cardCarouselOffice.carousel.slide
  a.carousel-control-next
  span.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2fff/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  border-radius: 5px;
  background-color: var(--cor-footer);
}
div.cardCarouselOffice.carousel.slide
  a.carousel-control-prev
  span.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2fff/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
  background-color: var(--cor-footer);
  border-radius: 5px;
}
.imgCarouselOffice {
  border-radius: 20px;
}

@keyframes colors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media screen and (max-width: 750px) {
  .GroupBottomCM {
    position: relative;
    /* transform: translateY(-79px); */
  }
}
@media screen and (max-width: 500px) {
  .GroupBottomCM {
    position: relative;
    /* transform: translateY(-79px); */
  }
}
@media screen and (max-width: 400px) {
  .section-title {
    margin-top: 40px;
    max-width: 1350px;
    height: 13%;
  }
}
.disable-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.logos {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.maintenance-title {
  color: #000080;
  font-size: 36px;
}

.deactivation-date {
  font-size: 18px;
  color: #333;
}
