.main-footer img {
  width: 14px;
}

.main-footer {
  padding-top: 20px;
  background-color: var(--cor-diferenca-portal);
  box-shadow: -1px -2px 12px 1px #22222259;
  border-top: 0.5px solid #e0e0e09d;
  font-family: "Inter";
}

.footer {
  width: 100%;
  padding: 5px 14px;
}

.footer a:hover {
  text-decoration: underline;
  color: #f5f5f5;
}
/* REDES SOCIAIS */
.card-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 2px;
}

.card-social a {
  color: var(--cor-texto-footer);
}

.link-social {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

/* DIREITOS AUTORAIS */
.link-end-footer {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--cor-texto-footer-desc);
}

.link-end-footer a {
  color: var(--cor-texto-footer-desc);
}
/*  */

.div-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 5px 10px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.list h2 {
  font-size: 18px;
  color: var(--cor-texto-footer);
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}

.list h2 a {
  color: var(--cor-texto-footer);
  font-size: 10px;
  text-decoration: underline;
  font-weight: 500;
}

.list li {
  color: var(--cor-texto-footer-desc);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px 6px;
  align-items: center;
  justify-content: flex-start;
  word-wrap: initial;
  word-break: keep-all;
}

@media screen and (max-width: 1210px) {
  .div-list {
    flex-wrap: wrap;
    gap: 20px 15px;
  }
  .list {
    justify-content: center;
    align-items: center;
  }
  .card-social {
    margin-top: 20px;
    margin-bottom: 22px;
  }
  .link-end-footer {
    text-align: center;
  }
}
